















































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging, debounce } from "@/utils/util";
import ExportData from "@/components/export-data/index.vue";
import { apiCardSaleOrderLists } from "@/api/productFile";
import { apiGetCustomerList } from "@/api/shop";

@Component({
    components: {
        lsPagination,
        ExportData,
        LsDialog,
    },
})
export default class cardSaleOverviewList extends Vue {
    apiCardSaleOrderLists = apiCardSaleOrderLists;

    pager: RequestPaging = new RequestPaging();

    searchObj: any = {
        orderNum: "",
        customerName: "",
        privateType: "",
        status: "",
    };
    custom_list: any[] = [];

    async getCustomerList() {
        const custom_list_array = await apiGetCustomerList({
            page_type: 0,
            type: 0,
        });
        this.custom_list = custom_list_array.lists;
    }

    // 列表
    getOrderList(page?: number) {
        page && (this.pager.page = page);
        this.pager.request({
            callback: apiCardSaleOrderLists,
            params: {
                ...this.searchObj,
            },
        });
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getOrderList(1);
    }

    // 去编辑商城
    toCardEdit(id: number | any, type: number | any): void {
        if (type == 1) {
            this.$router.push({
                path: "/card/makeCard/electronCardDetail",
                query: {
                    id: id,
                },
            });
        } else {
            this.$router.push({
                path: "/card/makeCard/voucherCardDetail",
                query: {
                    id: id,
                },
            });
        }
    }

    created() {
        this.getCustomerList();
        this.getOrderList(1);
    }
}
